import React from "react";
import { Link } from "gatsby";
import { graphql, useStaticQuery } from "gatsby";

const PostLocales = ({locale, localizations, slug, date}) => {
    const data = useStaticQuery(graphql`
        query {
            site {
                siteMetadata {
                    languages {
                        langs {
                            key
                            label
                        }
                        defaultLangKey
                    }
                }
            }
        }
  `)
    const languages = data.site.siteMetadata.languages

    if(localizations.data.length !== 0) {
        //has locales
        return (
            <div className="mt-4 mb-4">
                    {languages.langs.map(language => {
                        // Is this language published
                        let langExists = false;
                        if(locale === language.key) {
                            langExists = true;
                        } else {
                            localizations.data.forEach( locale => {
                                if(locale.attributes.locale === language.key) {
                                    langExists = true;
                                }
                            })
                        }

                        let langLink = '/'; // failure goes home

                        if(locale === languages.defaultLangKey) {
                            // page is default
                            if(language.key === languages.defaultLangKey) {
                                langLink = `/posts/${date}/${slug}`
                            } else {
                                localizations.data.forEach(locale => {
                                    if(language.key === locale.attributes.locale) {
                                        langLink = `/${language.key}/posts/${date}/${slug}`
                                    }
                                })
                            }
                        } else {
                            // page is not default
                            let primarySlug = '/'; // failure goes home
                            localizations.data.forEach(locale => {
                                if(locale.attributes.locale === languages.defaultLangKey) {
                                    primarySlug = `${slug}`
                                }
                            })

                            if(language.key === locale) {
                                langLink = `/${locale}/posts/${date}/${primarySlug}`
                            } else {
                                localizations.data.forEach(locale => {
                                    if(language.key === locale.attributes.locale) {
                                        if(locale.attributes.locale === languages.defaultLangKey) {
                                            langLink = `/posts/${date}/${primarySlug}`
                                        } else {
                                            langLink = `/${locale.attributes.locale}/posts/${date}/${primarySlug}`
                                        }
                                    }
                                })
                            }
                            

                        }
                        if(langExists) {
                            return (<Link to={langLink} className={`text-zinc-800 dark:text-white no-underline font-normal inline-block bg-zinc-200 hover:bg-zinc-300 dark:bg-zinc-800 hover:dark:bg-zinc-900 mr-1 mb-2 px-4 py-2 rounded-full leading-none ${(language.key === locale) ? 'bg-zinc-300 dark:bg-zinc-900' : ''} locale-item`}>{language.label}</Link>);
                        } else {
                            return (<></>)
                        }
                    })}
            </div>
        )
    } else {
        return (<></>)
    }
}

export default PostLocales