import React from 'react'
import {CopyToClipboard} from 'react-copy-to-clipboard';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTwitter, faFacebookF } from '@fortawesome/free-brands-svg-icons'
import { faLink } from '@fortawesome/free-solid-svg-icons'

class ShareLinks extends React.Component {
  constructor(props) {
    super()
    this.value = props.url

    this.state = {
      copied: false,
    }
  }


  render() {
    return (
      <>
        <a target="_blank" rel="noreferrer noopener" href={'http://www.twitter.com/share?url='+this.value} className="block mt-4 first:mt-2 ml-0 text-zinc-900 py-0 px-4 hover:text-zinc-500 dark:text-zinc-300 my-3 dark:hover:text-white">
          <span className="mr-2 ">
            <FontAwesomeIcon icon={faTwitter} />
          </span>
          Twitter
        </a>
        <hr class="" />
        <a target="_blank" rel="noreferrer noopener" href={'https://www.facebook.com/sharer/sharer.php?u='+this.value} className="block mt-4 first:mt-2 ml-0 text-zinc-900 py-0 px-4 hover:text-zinc-500 dark:text-zinc-300 my-3 dark:hover:text-white">
          <span className="mr-2">
            <FontAwesomeIcon icon={faFacebookF} />
          </span>
          Facebook
        </a>
        <hr class="" />
        <CopyToClipboard text={this.value} onCopy={ () => this.setState({copied: true}) }>
          <button className="block mt-4 first:mt-2 ml-0 text-zinc-900 py-0 px-4 hover:text-zinc-500 dark:text-zinc-300 my-3 dark:hover:text-white">
            <span className="mr-2">
              <FontAwesomeIcon icon={faLink} />
            </span>
            Copy Link { this.state.copied ? <span className="text-red-800 dark:text-red-400">Copied.</span> : null }
          </button>
        </CopyToClipboard>
      </>
    )
  }
}

export default ShareLinks;